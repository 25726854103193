import { EntityRequestOrUndefined } from 'src/models/api/request.model';
import { ApiSearchIncludeResponse } from 'src/models/api/response.model';
import { ErrorNormalized } from 'src/models/errors/error.model';
import { ActiveListState } from 'src/models/store-models/entity-list-state.model';
import { VerificationListRequest } from 'src/models/verifications/verification.models';
import { EmployerVerificationInclude, Verification } from 'src/typings/api-models.generated';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const verificationResetState = createAction('VERIFICATION_RESET_STATE')();

export const verificationSetActive =
    createAction('VERIFICATION:SET_ACTIVE')<EntityRequestOrUndefined>();
export const verificationListSetActive = createAction('VERIFICATION_LIST:SET_ACTIVE')<
    ActiveListState<VerificationListRequest>
>();
export const verificationListClear = createAction('VERIFICATION_LIST:CLEAR')<{
    excludeActive: boolean;
}>();

export const verificationListAsync = createAsyncAction(
    'VERIFICATION_LIST:REQUEST',
    'VERIFICATION_LIST:SUCCESS',
    'VERIFICATION_LIST:FAILURE',
    'VERIFICATION_LIST:CANCEL'
)<
    VerificationListRequest,
    ApiSearchIncludeResponse<Verification, VerificationListRequest, EmployerVerificationInclude>,
    [ErrorNormalized, VerificationListRequest],
    undefined
>();
