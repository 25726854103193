import { PageTitle } from 'src/ui/shared/components/page-components/PageTitle';
import { useStrings } from 'src/ui/shared/hooks/strings.hook';

export const VerificationPageNav = (): React.ReactElement => {
    const {
        nav: {
            application: { verifications },
        },
    } = useStrings();

    return <PageTitle>{verifications}</PageTitle>;
};
