import isEqual from 'lodash/isEqual';
import { useCallback, useMemo, useState } from 'react';
import { Field, useFormContext } from 'react-hook-form';
import Select, { ValueType, createFilter } from 'react-select';
import { useFormError } from 'src/ui/shared/hooks/form-error.hook';
import { formControlIdentifier } from 'src/ui/shared/identifiers/form-control.identifier';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { HookControlProps } from '../../HookForm';
import { ControlFormGroup } from '../../shared/ControlFormGroup';
import { useReactSelect } from './react-select.hooks';

export interface ReactSelectOption<T = string | number> {
    label: React.ReactNode;
    value: T;
    tokens: string[];
}

export interface SingleSelectControlProps<T = string | number> extends HookControlProps {
    options: ReactSelectOption<T>[];
    allowEmpty?: boolean;
    filter?: ReturnType<typeof createFilter>;
    compareOverride?: (value: T, other: any) => boolean;
    onChange?: (value: T | undefined) => void;
}

export const SingleSelectControl = <T,>(
    props: SingleSelectControlProps<T>
): React.ReactElement<any, any> | null => {
    const {
        name,
        errorName,
        rules,
        placeholder,
        tabIndex,
        disabled,
        allowEmpty,
        options,
        filter,
        errorIndex,
        compareOverride,
        onChange,
    } = props;
    const optionsToUse: ReactSelectOption<T>[] = useMemo(
        () =>
            allowEmpty ? [{ label: '', value: '' as any as T, tokens: [] }, ...options] : options,
        [allowEmpty, options]
    );

    const { register, setValue, watch, control } = useFormContext();
    const [hasSetValue, setHasSetValue] = useState(false);

    const controlError = useFormError(errorName || name, errorIndex);
    const innerRef = control._fields[name];

    useDeepCompareEffect(() => {
        register(name, rules);
    }, [register, name, rules]);

    const { ref, onBlur } = useReactSelect(name, { isMulti: false });

    const onChangeInner = useCallback(
        (value: ValueType<ReactSelectOption<T>, false>) => {
            const valueToSet = value ? value.value : undefined;

            setValue(name, valueToSet, {
                shouldValidate: true,
                shouldDirty: true,
            });
            if (!hasSetValue) {
                setHasSetValue(true);
            }

            onChange?.(valueToSet);
        },
        [setValue, name, hasSetValue, onChange]
    );

    const valueCompare = useMemo(() => {
        const fnc = compareOverride || isEqual;
        return fnc;
    }, [compareOverride]);

    const watchValue = watch(name);

    const getValueOption = useCallback(
        (list: ReactSelectOption<T>[]) => {
            // have to use isEqual as T could be an object
            // be weary of overly long lists of overly long complex objects
            return list.find(c => valueCompare(c.value, watchValue)) || null;
        },
        [valueCompare, watchValue]
    );

    const defaultFilter: ReturnType<typeof createFilter> = (candidate, rawInput) => {
        const lower = rawInput.toLowerCase();
        return (candidate.data as ReactSelectOption<T>).tokens.some(t =>
            t.toLowerCase().startsWith(lower)
        );
    };

    return (
        <ControlFormGroup {...props}>
            <Select
                ref={ref}
                isDisabled={disabled || (innerRef as Field | undefined)?._f.ref.disabled}
                tabIndex={tabIndex ? tabIndex.toString() : null}
                placeholder={placeholder}
                options={optionsToUse}
                isMulti={false}
                onChange={onChangeInner}
                name={`inner-select-${name}`}
                onBlur={onBlur}
                classNamePrefix="react-select"
                className={`react-select-container ${formControlIdentifier.reactSelect(name)} ${
                    controlError ? 'is-invalid' : ''
                }`}
                // defaultValue={defaultValueInner}
                filterOption={filter || defaultFilter}
                value={getValueOption(optionsToUse)}
            />
        </ControlFormGroup>
    );
};
