import { Severity } from '@sentry/react';
import { Observable, of, throwError } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { catchError, switchMap } from 'rxjs/operators';
import { getClientConfig } from 'src/clients-internal/configuration.init';
import { logMessage } from 'src/ui/features/sentry/helpers/sentry.helper';
import { storeRegistry } from 'src/logic/store.registry';
import { FetchHelperOptions, UnwrappedJsonResponse } from '../../models/api/service.model';
import { ErrorNormalized } from '../../models/errors/error.model';
import { handleFetchResponse } from 'src/ui/features/mini-profiler/MiniProfiler';

export const COOKIE_EXPIRATION_HEADER = 'X-Authentication-Cookie-Expiration';
export type FetchHelperMethods = 'GET' | 'POST' | 'DELETE' | 'PUT';

function isResponse<T = any>(wrappedResponse: any): wrappedResponse is UnwrappedJsonResponse<T> {
    return (
        !!wrappedResponse &&
        !!wrappedResponse.response &&
        typeof wrappedResponse.response.ok === 'boolean'
    );
}

const HEADERS: Record<string, string> = {
    'Access-Control-Allow-Headers': COOKIE_EXPIRATION_HEADER,
    Accept: 'application/json',
};

const defaultOptions: FetchHelperOptions = {
    // this is for my own sanity because I believe it will be confusing
    // if I continue to send this through on all requests
    // someone debugging the request may think this is what is responible for
    // authentication. When it is not. Therefore, I'm only sending it when it's
    // needed (although the request will still work if you forget to add this
    // options on a request where it's not necessary.)
    includeAccessToken: false,
    // CORS tends to be much more secure these days, if you want to
    // include credentials, then the include option should be used.
    // This is why it's a default.
    // However, if you still want to make non-authorized cross-origin requests
    // AND the response will only use a wildcard (*) Access-Control-Allow-Origin header
    // you MUST set this to omit.
    credentials: 'include',

    // tells fetch to use the response.blob() method, instead of .json or .text
    // this is only used when downloading files from careerhub to re-upload files
    // to central. The entire process is borked and should probably be rethunk.
    expectBlobResponse: false,
};

const getHeaders = (
    options: FetchHelperOptions,
    isFormDataRequest: boolean
): Record<string, string> => {
    // Not really sure if I like this here, but it does mean that I don't
    // have to pass through the access_token into every service...
    // NOTE: This is only necessary if the pre-auth flows (options/ registration/ identity)
    // where the access_token from Identity is used for authentication before being replaced by
    // CareerHub's cookie auth.
    // or now MSP.
    const accessToken = storeRegistry.get()?.getState().authentication.oidcUser?.access_token;

    const authHeader: Record<string, string> =
        options.includeAccessToken && accessToken ? { Authorization: `Bearer ${accessToken}` } : {};

    const contentTypeHeader: Record<string, string> = isFormDataRequest
        ? {}
        : { 'Content-Type': 'application/json' };
    return {
        ...HEADERS,
        ...authHeader,
        ...contentTypeHeader,
    };
};

const get: <T>(
    url: string,
    options?: FetchHelperOptions
) => Observable<UnwrappedJsonResponse<T>> = (url, options) =>
    fetchRequest(url, 'GET', undefined, options);

const post: <T>(
    url: string,
    body: any,
    options?: FetchHelperOptions
) => Observable<UnwrappedJsonResponse<T>> = (url, body, options) =>
    fetchRequest(url, 'POST', body, options);

const put: <T>(
    url: string,
    body: any,
    options?: FetchHelperOptions
) => Observable<UnwrappedJsonResponse<T>> = (url, body, options) =>
    fetchRequest(url, 'PUT', body, options);

const del: <T>(
    url: string,
    options?: FetchHelperOptions
) => Observable<UnwrappedJsonResponse<T>> = (url, options) =>
    fetchRequest(url, 'DELETE', undefined, options);

const fetchRequest = <T>(
    url: string,
    method: FetchHelperMethods,
    body?: any,
    options: FetchHelperOptions = defaultOptions
) => {
    // bleh, this could be better, it's only ever manipulated by the attachment control
    const isFormDataRequest = body instanceof FormData;
    const headers = getHeaders(options, isFormDataRequest);

    // replaces undefined values to null
    // since undefined doesn't exist in JSON
    const bodyToSend = body
        ? isFormDataRequest
            ? body
            : JSON.stringify(body) // , (_, value) => (typeof value === 'undefined' ? null : value)) // temp remove, need to look into this
        : undefined;

    return fromFetch(url, {
        method: method,
        mode: 'cors',
        body: bodyToSend,
        credentials: options.credentials,
        headers: headers,
    }).pipe(
        switchMap(async response => {
            handleFetchResponse(response);
            const contentType = response.headers.get('content-type');

            let json: any = undefined;
            let text: string | undefined = undefined;
            let blob: Blob | undefined = undefined;

            if (options.expectBlobResponse) {
                blob = await response.blob();
            } else if (
                contentType &&
                // central uses the weird 'problem+json' content type, idk why
                ['application/json', 'text/json', 'application/problem+json'].some(
                    c => contentType.indexOf(c) !== -1
                )
            ) {
                json = await response.json();
            } else {
                text = await response.text();
            }

            const toReturn: UnwrappedJsonResponse<T> = {
                request: {
                    url,
                    method,
                    body,
                    options,
                },
                response,
                json,
                text,
                blob,
            };

            return toReturn;
        }),
        switchMap(awaited => {
            if (awaited.response.ok) {
                // OK return data
                return of(awaited);
            } else {
                // this handles all responses that return a non 200-299
                // this will be normalised in the below catch error
                return throwError(awaited);
            }
        }),
        catchError((err: unknown) => {
            console.log('responseError', err);
            // Network or other error, handle appropriately
            if (!isResponse(err)) {
                // trying to handle for very common CORS error
                if (
                    (err as Error)?.message === 'Failed to fetch' &&
                    (err as Error)?.name === 'TypeError'
                ) {
                    const possibleCorsError: ErrorNormalized = {
                        status: 0,
                        statusText: 'Unknown',
                        message:
                            'Failed to fetch error, this can also be caused by a CORs misconfiguration in CareerHub',
                    };
                    return throwError(possibleCorsError);
                }

                const nonResponseError: ErrorNormalized = {
                    status: 0,
                    statusText: 'Unknown',
                    message: (err as Error)?.message || 'Unexpected Error',
                };
                return throwError(nonResponseError);
            }

            // there are two main alt cases
            // 400 bad requests; which are usually form validation errors
            // 401 Unauthenticated; authentication responses
            const responseError: ErrorNormalized = {
                status: err.response.status,
                statusText: err.response.status.toString(),

                // err.json?.message - standard careerhub error message
                // err.json?.errors[0]?.message - Central v2 error format, this is a bit meh, as it only gets the first error
                // err.text - possible non-json response
                // err.response.statusText - human-friendly response based on response.status, not well supported
                // if all else fails, 'unexpected error'
                message:
                    err.json?.message ||
                    err.json?.errors[0]?.message ||
                    err.text ||
                    err.response.statusText ||
                    'Unexpected Error',
                validationErrors: {},
            };

            // standard careerhub 400 response - validation errors
            if (err.response.status === 400 && err.json && err.json.modelState) {
                Object.keys(err.json.modelState).forEach(key => {
                    const propertyName = key.substring(key.lastIndexOf('.') + 1);
                    responseError.validationErrors![propertyName] = err.json.modelState[key];
                });
            }

            // central - v2 - validation errors
            // AND CareerHub fluent validation responses
            // V2 has a { code: string, message: string }[] format (and sometimes additional properties)
            if (err.response.status === 400 && err.json && err.json.errors) {
                // these should never really be hit, as there is nothing the user can do about it
                // so I also log this as errors in sentry so that I am notified when this eventually, unavoidably, breaks
                if (err.response.url.startsWith(getClientConfig().centralApiPath)) {
                    logMessage('Cental Validation Error', Severity.Error, err.json, {
                        central: 'true',
                    });
                }
                if (Array.isArray(err.json.errors)) {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                    err.json.errors.forEach((err: { property: string; message: string }) => {
                        responseError.validationErrors![err.property] = err.message;
                    });
                }
            }

            // identity - validation errors
            // these are a bit dicked because it has the most simple response that overlaps with both Central and CareerHub
            // so we check the url being hit
            if (
                err.response.status === 400 &&
                err.json &&
                err.response.url.startsWith(getClientConfig().identityAuthority)
            ) {
                Object.keys(err.json).forEach(key => {
                    // identity uppercases props cause it's all so basic
                    const hackKey = key[0].toLowerCase() + key.substring(1);
                    responseError.validationErrors![hackKey] = err.json[key];
                });
            }

            // don't log the following statuses
            if (![400, 401, 404].includes(responseError.status)) {
                // request-id in the header allows us to connect back end requests
                // with front-end errors. Request Id will only be present against Pete-APIs
                const requestId = err.response.headers.get('request-id');

                logMessage(
                    `Error from API with status: ${responseError.status}`,
                    Severity.Error,
                    {
                        ...err,
                        // just send a boolean throgh for blob
                        blob: !!err.blob,
                        requestId,
                    },
                    {
                        hasRequestId: requestId ? 'true' : 'false',
                        apiResponseStatus: responseError.status.toString(),
                        apiRequestUrl: err.request.url,
                    }
                );
            }

            return throwError(responseError);
        })
    );
};

export const fetchHelper = {
    post,
    put,
    del,
    get,
    fetch: fetchRequest,
};
