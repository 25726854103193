import { getClientConfig } from 'src/clients-internal/configuration.init';
import { apiStringify } from 'src/logic/helpers/api-query-string.helper';
import { fetchHelper } from 'src/logic/helpers/fetch.helper';
import { ApiSearchIncludeResponse } from 'src/models/api/response.model';
import { ObsApiExpected } from 'src/models/api/service.model';
import { VerificationListRequest } from 'src/models/verifications/verification.models';
import { EmployerVerificationInclude, Verification } from 'src/typings/api-models.generated';

const baseUrl = () => getClientConfig().careerHubBasePath;

export const list = (
    request: VerificationListRequest
): ObsApiExpected<
    ApiSearchIncludeResponse<Verification, VerificationListRequest, EmployerVerificationInclude>
> => {
    return fetchHelper.get(
        `${baseUrl()}/services/employers/v1/verifications${apiStringify(request)}`
    );
};
