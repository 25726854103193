import { asyncEpicBase } from 'src/logic/helpers/epics/async.epic-helper';
import { listEpic } from 'src/logic/helpers/epics/list-route.epic-helper';
import { verificationPaths } from 'src/routes/employer/paths/verification.paths';
import * as actions from './verification.actions';

export const verificationListEpic = listEpic({
    path: verificationPaths.list,
    getParams: (action, _, { queryParams }) => ({ skip: 0, status: [] }),
    getListState: state => state.eventUnions,
    setActiveActionCreator: actions.verificationListSetActive,
    cancelActionCreator: actions.verificationListAsync.cancel,
    requestActionCreator: actions.verificationListAsync.request,
});

export const verificationListAsyncEpic = asyncEpicBase(
    actions.verificationListAsync,
    (services, ra) => services.api.verifications.list(ra.payload),
    {
        success: result => actions.verificationListAsync.success(result.json),
        failure: (error, ra) => actions.verificationListAsync.failure(error, ra.payload),
    }
);
