import qs, { IParseOptions, ParsedQs } from 'qs';
import {
    EVENT_REQUEST_STATUS_OPTIONS,
    EVENT_STATUS_OPTIONS,
} from 'src/models/events/event-status.model';
import { ORGANISATION_TYPE_OPTIONS } from 'src/models/organisation/organisation-types.model';
import { RegisterName } from 'src/models/register/register-name.model';
import { Optional } from 'utility-types';
import { EntityPageRequest } from '../../../models/api/request.model';
import { JOB_STATUS_OPTIONS } from '../../../models/jobs/job-types.model';
import { ContactPageRequest } from '../contacts/contact.service';
import { EventUnionPageRequest } from '../event-unions/event-union.service';
import { JobPageRequest } from '../jobs/job.service';
import { SimilarOrganisationsRequest } from '../register/register.service';

type qsParsedType = string | ParsedQs | (string | ParsedQs)[] | undefined;

const PARSE_OPTIONS: Readonly<IParseOptions> & { decoder?: never } = {
    ignoreQueryPrefix: true,
    parseArrays: false,
};

function getKnownArrayValues<TStatus extends string>(
    queryString: qsParsedType,
    fromConstArray: ReadonlyArray<TStatus>
) {
    if (typeof queryString !== 'string') {
        return [];
    }

    return queryString
        .split(',')
        .filter(i =>
            fromConstArray.map(i => i.toLowerCase()).includes(i.toLowerCase())
        ) as TStatus[];
}

const getPage: (param: qsParsedType) => number | undefined = param => {
    const parsed = Number(param) || undefined;
    if (!parsed) {
        return undefined;
    }

    return parsed === 1 ? undefined : parsed;
};

const getExpectedString: (param: qsParsedType) => string | undefined = param => {
    return typeof param === 'string' ? param : undefined;
};

// there's no changing of list size yet,
// but when there is this can be updated.
const defaultTake = 30;
const getSkipTakeFromPage: (param: qsParsedType) => EntityPageRequest = param => {
    const page = getPage(param);
    return page ? { skip: (page - 1) * defaultTake } : {};
};

export const parseExpected = <TExpected extends Record<string, any>>(
    value: string
): Optional<TExpected> => {
    return qs.parse(value, PARSE_OPTIONS) as Optional<TExpected>;
};

export function jobListParams(search: string): JobPageRequest {
    const parsed = qs.parse(search, PARSE_OPTIONS);
    const statuses = getKnownArrayValues(parsed.status, JOB_STATUS_OPTIONS);
    const skipTake = getSkipTakeFromPage(parsed.page);

    return {
        ...skipTake,
        status: statuses.length > 0 ? statuses : undefined,
    };
}

export function contactListParams(search: string): ContactPageRequest {
    const parsed = qs.parse(search, PARSE_OPTIONS);
    const isActive =
        typeof parsed.isActive !== 'string'
            ? undefined
            : parsed.isActive.toLowerCase() === 'true'
            ? true
            : false;

    const skipTake = getSkipTakeFromPage(parsed.page);

    return {
        ...skipTake,
        isActive,
    };
}

export function similarListParams(
    search: string,
    defaultValues: RegisterName
): SimilarOrganisationsRequest {
    const parsed = parseExpected<RegisterName & { page: string }>(search);
    const skipTake = getSkipTakeFromPage(parsed.page);

    const employerTypeArray = getKnownArrayValues(parsed.employerType, ORGANISATION_TYPE_OPTIONS);

    return {
        ...skipTake,
        countryCode: getExpectedString(parsed.countryCode) || defaultValues.countryCode,
        employerType:
            employerTypeArray.length > 0 ? employerTypeArray[0] : defaultValues.employerType,
        name: getExpectedString(parsed.name) || defaultValues.name,
        // this needs to be set for now since careerhub 404s even tho the parameter is nullable
        businessId: getExpectedString(parsed.businessId) || '',
    };
}

export function eventUnionListParams(search: string): EventUnionPageRequest {
    const parsed = qs.parse(search, PARSE_OPTIONS);
    const eventStatuses = getKnownArrayValues(parsed.eventStatus, EVENT_STATUS_OPTIONS);
    const eventRequestStatuses = getKnownArrayValues(
        parsed.eventRequestStatus,
        EVENT_REQUEST_STATUS_OPTIONS
    );
    const skipTake = getSkipTakeFromPage(parsed.page);

    return {
        ...skipTake,
        eventStatus: eventStatuses.length > 0 ? eventStatuses : undefined,
        eventRequestStatus: eventRequestStatuses.length > 0 ? eventRequestStatuses : undefined,
    };
}

export function entityListParams(search: string): EntityPageRequest {
    const parsed = qs.parse(search, PARSE_OPTIONS);
    const skipTake = getSkipTakeFromPage(parsed.page);

    return skipTake;
}

export function getPageParam(search: string): number | undefined {
    const parsed = qs.parse(search, PARSE_OPTIONS);
    return getPage(parsed.page);
}

export function parse(search: string): qs.ParsedQs {
    return qs.parse(search, PARSE_OPTIONS);
}

export function stringify(obj: any) {
    return qs.stringify(obj, {
        addQueryPrefix: true,
    });
}
