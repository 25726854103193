import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import {
    faBriefcase,
    faBuilding,
    faCheck,
    faClipboard,
    faHome,
    faUser,
    faUserTie,
} from '@fortawesome/free-solid-svg-icons';
import { eventPathCreator } from 'src/routes/employer/paths/event.paths';
import { organisationPaths } from 'src/routes/employer/paths/organisation.paths';
import { verificationPaths } from 'src/routes/employer/paths/verification.paths';
import { useContent } from 'src/ui/features/content/hooks/content.hook';
import { useEventsEnabled } from 'src/ui/features/events/hooks/events-enabled.hook';
import { useFormSubmissionsEnabled } from 'src/ui/features/forms/hooks/form-submissions-enabled.hook';
import { useIsJobsEnabled } from 'src/ui/features/jobs/hooks/is-jobs-enabled.hook';
import { useClient } from 'src/ui/shared/hooks/client.hook';
import { useIsCareerHubVersion } from 'src/ui/shared/hooks/is-careerhub-version.hook';
import { contactPathCreator } from '../../../../../routes/employer/paths/contact.paths';
import { formPaths } from '../../../../../routes/employer/paths/form.paths';
import { individualPaths } from '../../../../../routes/employer/paths/individual.paths';
import { jobPathCreator } from '../../../../../routes/employer/paths/job.paths';
import { useAuthentication } from '../../../../features/authentication/hooks/authentication.hook';
import { useStrings } from '../../../hooks/strings.hook';
import { LinkWrapper, LinkWrapperOptions } from '../../router/LinkWrapper';

interface Props {
    as: LinkWrapperOptions;
    className?: string;
    hideHome?: boolean;
}

export const ApplicationNavItems = ({
    as,
    className,
    hideHome,
}: Props): React.ReactElement<any, any> | null => {
    const { isIndividual, isOrganisation } = useAuthentication();
    const {
        nav: { application },
    } = useStrings();
    const { enableHomeMenuItem } = useClient();
    const { firstCollection } = useContent('employerContent');
    const { isEventsEnabled } = useEventsEnabled();
    const isJobsEnabled = useIsJobsEnabled();
    const formSubmissionsEnabled = useFormSubmissionsEnabled();
    const v5_17 = useIsCareerHubVersion(5, 17);

    return (
        <>
            {!hideHome && enableHomeMenuItem && (
                <LinkWrapper
                    to="/"
                    icon={faHome}
                    iconSize="lg"
                    as={as}
                    className={className}
                    exact={true}
                >
                    {firstCollection?.title || application.home}
                </LinkWrapper>
            )}
            {isJobsEnabled && (
                <LinkWrapper
                    to={jobPathCreator.listDefault()}
                    icon={faBriefcase}
                    iconSize="lg"
                    as={as}
                    className={className}
                >
                    {application.jobs}
                </LinkWrapper>
            )}

            {isIndividual && (
                <LinkWrapper
                    to={individualPaths.details}
                    icon={faUser}
                    iconSize="lg"
                    as={as}
                    className={className}
                >
                    {application.individualDetails}
                </LinkWrapper>
            )}

            {isOrganisation && (
                <>
                    {isEventsEnabled && (
                        <LinkWrapper
                            to={eventPathCreator.listDefault()}
                            icon={faCalendarAlt}
                            iconSize="lg"
                            as={as}
                            className={className}
                        >
                            {application.events}
                        </LinkWrapper>
                    )}
                    <LinkWrapper
                        to={organisationPaths.details}
                        icon={faBuilding}
                        iconSize="lg"
                        as={as}
                        className={className}
                    >
                        {application.organisationDetails}
                    </LinkWrapper>
                    <LinkWrapper
                        to={contactPathCreator.list({ isActive: true })}
                        icon={faUserTie}
                        iconSize="lg"
                        as={as}
                        className={className}
                    >
                        {application.contacts}
                    </LinkWrapper>
                    {v5_17 && (
                        <LinkWrapper
                            to={verificationPaths.list}
                            icon={faCheck}
                            iconSize="lg"
                            as={as}
                            className={className}
                        >
                            {application.verifications}
                        </LinkWrapper>
                    )}
                </>
            )}
            {formSubmissionsEnabled && (
                <LinkWrapper
                    to={formPaths.submissions}
                    icon={faClipboard}
                    iconSize="lg"
                    as={as}
                    className={className}
                >
                    {application.formSubmissions}
                </LinkWrapper>
            )}
        </>
    );
};
