export const verificationPaths = {
    base: '/verifications',
    sourceList: '/verifications/sources',
    sourceDetails: '/verifications/sources/:sourceId',
    list: '/verifications/list',
    details: '/verifications/list/:verificationId',
};

export const verificationPathCreator = {
    sourceDetails: (id: string) => `${verificationPaths.sourceList}/${id}`,
};
